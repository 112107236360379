import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"
import { Box, Typography, Button, CircularProgress, Link } from "@mui/material"
import { makeStyles } from "@mui/styles"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import useScript from "react-script-hook"
import { InitVardenSeTrackingCallback } from "utils/initVardenSe"
import TagManager from "react-gtm-module"
import config from "config"
import { sessionType } from "utils/sessionType"
import { setCookie } from "utils/setCookie"
import Cookie from "js-cookie"
import getUserAge from "utils/getUserAge"
import isFreeCardValid from "utils/isFreeCardValid"
import { getPatientType } from "utils/getPatientType"
import { trackEvent } from "utils/trackEvent"
import { useAdsTracker } from "components/AdsTracker"
import VerifyEmailAndPhoneDialog from "components/VerifyEmailAndPhoneDialog"
import posthog from "posthog-js"
import { getOsAndBrowser } from "utils/getOsAndBrowser"
import { LATE_CANCELLATION_FEE } from "utils/constants"

const { isTest, isRc, abTestFeatureFlag, abTestGroupName } = config
const smallWindowHeightThreshold = 740

const useStyles = makeStyles((theme) => ({
  root: ({ isSmallWindowHeight }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    "&.ikbt": {
      justifyContent: "start",
      marginTop: "15vh",
      [theme.breakpoints.down("sm")]: {
        justifyContent: isSmallWindowHeight && "center",
        marginTop: isSmallWindowHeight ? 0 : "8vh",
      },
    },
  }),
  icon: {
    height: 120,
    width: 120,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      height: 90,
      width: 90,
    },
  },
  header: {
    textAlign: "center",
    fontSize: "3rem",
    "&.ikbt": {
      marginBottom: theme.spacing(2.5),
      fontSize: "2.3rem",
      fontWeight: 600,
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.5rem",
      },
      [theme.breakpoints.down(380)]: {
        marginBottom: theme.spacing(1.5),
      },
      [theme.breakpoints.down(330)]: {
        fontSize: "1.3rem",
      },
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.8rem",
    },
    [theme.breakpoints.down(330)]: {
      fontSize: "1.5rem",
    },
  },
  text: ({ isSmallWindowHeight }) => ({
    textAlign: "center",
    fontSize: "1.4rem",
    margin: theme.spacing(2, 0),
    width: "70%",
    "&.ikbt": {
      [theme.breakpoints.down("sm")]: {
        textAlign: "left",
        fontSize: isSmallWindowHeight ? "1rem" : "1.2rem",
        width: "80%",
      },
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1.3rem",
      width: "80%",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.down(330)]: {
      fontSize: "1.1rem",
      width: "90%",
    },
  }),
  button: {
    marginTop: theme.spacing(3),
    borderRadius: theme.spacing(2.5),
    minWidth: 230,
    "&.ikbt": {
      marginTop: theme.spacing(4),
      textTransform: "none",
      [theme.breakpoints.down(380)]: {
        marginTop: theme.spacing(3),
      },
    },
    [theme.breakpoints.down("sm")]: {
      borderRadius: theme.spacing(12.5),
      width: "80%",
      fontSize: "1rem",
      lineHeight: 2.1,
    },
  },
}))

function sendAdtractionData(transactionId) {
  const data = {
    dataLayer: {
      transactionId,
      transactionTotal: 0,
    },
  }

  TagManager.dataLayer(data)
}

function addVideoSessionDataToGTM(
  userId,
  userAge,
  gender,
  zipCode,
  isSormland,
  isFreeAge,
  hasValidFreeCard,
  isUserFirstSession,
  email,
  phoneNumber,
  gclid,
  fbclid
) {
  const hasValidPayment = !isFreeAge && !hasValidFreeCard
  const patientType = getPatientType({
    isFreeAge,
    hasValidFreeCard,
    hasValidPayment,
  })
  const userAgent = window?.navigator?.userAgent
  const { os, browser } = getOsAndBrowser(userAgent)

  trackEvent("sessionCreated", {
    userId,
    userAge,
    gender: gender === "female" ? "F" : "M",
    zipCode: zipCode || "",
    patientType,
    isSormland,
    newCustomer: isUserFirstSession,
    sessionType: "video",
    bookedByPatient: true,
    email,
    phoneNumber,
    userAgent,
    os,
    browser,
    gclid,
    fbclid,
  })

  // ten minutes is 1/144 of day
  const tenMinutes = 1 / 144
  setCookie("videoSessionInGtm", true, tenMinutes)

  if (isTest || isRc) {
    posthog.capture("sessionCreated", {
      userId,
      patientType,
      isSormland,
      newCustomer: isUserFirstSession,
      sessionType: "video",
    })
  }
}

const PaymentSuccess = ({
  userId,
  isSormland,
  paidSessionNumber,
  socialSecurity,
  freeCard,
  email,
  phoneNumber,
  gender,
  zipCode,
}) => {
  const [adsState, setAdsState] = useAdsTracker()

  const [isSmallWindowHeight, setIsSmallWindowHeight] = useState(
    window.innerHeight < smallWindowHeightThreshold
  )
  const classes = useStyles({ isSmallWindowHeight })
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const paymentSessionType = parseInt(searchParams.get("type"))
  const ikbtInvite = searchParams.get("ikbtInvite") === "true"
  const [loading, setLoading] = useState(true)
  const { isDev, braiveLoginUrl } = config
  const isVideoSessionType = paymentSessionType === sessionType.VIDEO
  const isVideoSessionAddedToGTM = Cookie.get("videoSessionInGtm")
  const userAge = getUserAge(socialSecurity)
  const isFreeAge = userAge < 20 || userAge >= 85
  const hasValidFreeCard = isFreeCardValid(freeCard?.expirationDate)
  const isUserFirstSession = paidSessionNumber === 1
  const [openVerifyEmailAndPhoneDialog, setOpenVerifyEmailAndPhoneDialog] =
    useState(isUserFirstSession)

  // eslint-disable-next-line
  const [_, errorVardenSe] = useScript({
    src: "https://varden-scripts.s3-eu-west-1.amazonaws.com/booking/vbnotification.js",
    checkForExisting: true,
    onload: () => console.log("vardense loaded"),
  })

  const abTestGroup =
    isUserFirstSession &&
    posthog.getFeatureFlag(abTestFeatureFlag) === abTestGroupName

  useEffect(() => {
    if (isUserFirstSession) {
      try {
        posthog.capture("bookingCompleted", {
          userId,
          abTestGroup,
        })
      } catch (error) {
        console.log(error)
      }
    }
    if (!isDev) {
      sendAdtractionData(userId)
      if (isVideoSessionType && !isVideoSessionAddedToGTM) {
        addVideoSessionDataToGTM(
          userId,
          userAge,
          gender,
          zipCode,
          isSormland,
          isFreeAge,
          hasValidFreeCard,
          isUserFirstSession,
          email,
          phoneNumber,
          adsState.gclid,
          adsState.fbclid
        )

        // Reset ads state.
        setAdsState({})
      }

      if (!errorVardenSe) {
        InitVardenSeTrackingCallback(userId)
      } else {
        console.warn("InitVardenSeTrackingCallback script not loaded")
      }
    }

    setLoading(false)
    const popStateAction = () => {
      navigate("/")
    }
    const handleResize = () => {
      setIsSmallWindowHeight(window.innerHeight < smallWindowHeightThreshold)
    }
    window.addEventListener("popstate", popStateAction)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("popstate", popStateAction)
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  if (loading) {
    return (
      <Box className={classes.root}>
        <CircularProgress size={50} color="primary" />
      </Box>
    )
  }

  return (
    <Box className={`${classes.root} ${ikbtInvite && "ikbt"}`}>
      {!ikbtInvite && (
        <CheckCircleOutlineIcon color="primary" className={classes.icon} />
      )}
      <Typography
        color="primary"
        className={`${classes.header} ${ikbtInvite && "ikbt"}`}
      >
        Tack för din bokning!
      </Typography>
      <Typography className={`${classes.text} ${ikbtInvite && "ikbt"}`}>
        {isVideoSessionType
          ? "Du har nu fått en bekräftelse skickad till din e-post."
          : "Nu kan du börja skriva med din psykolog."}
      </Typography>
      {ikbtInvite ? (
        <>
          <Typography className={`${classes.text} ikbt`}>
            Du ska även ha fått en inbjudan till att skapa ett konto i Braive.
          </Typography>
          <Typography className={`${classes.text} ikbt`}>
            Skapa ett konto och svara på frågorna innan ditt nästa samtal, det
            tar ca 5 minuter. På så sätt får din psykolog mer information om
            ditt mående.
          </Typography>
          <Button
            className={`${classes.button} ikbt`}
            variant="contained"
            href={braiveLoginUrl}
            target="_blank"
          >
            Gå till frågorna
          </Button>
          <Link
            mt={2}
            href={`${window.location.origin}/?after-payment=true`}
            underline="always"
          >
            {isVideoSessionType ? "Tillbaka hem" : "Till chatten"}
          </Link>
        </>
      ) : (
        <>
          <Typography className={classes.text}>
            Du kan avboka ditt videosamtal kostnadsfritt upp till 24 timmar
            innan. Om du avbokar senare, ansluter mer än 10 minuter sent eller
            uteblir debiteras du {LATE_CANCELLATION_FEE}kr.
          </Typography>
          <Button
            className={classes.button}
            variant="contained"
            onClick={() => navigate("/?after-payment=true")}
          >
            {isVideoSessionType ? "Tillbaka hem" : "Till chatten"}
          </Button>
        </>
      )}
      <VerifyEmailAndPhoneDialog
        openDialog={openVerifyEmailAndPhoneDialog}
        onClose={() => setOpenVerifyEmailAndPhoneDialog(false)}
        userEmail={email}
        userPhoneNumber={phoneNumber}
      />
    </Box>
  )
}

const mapStateToProps = (state) => {
  const { user } = state
  const {
    id: userId,
    isSormland,
    paidSessionNumber,
    socialSecurity,
    freeCard,
    email,
    phoneNumber,
    gender,
    zipCode,
  } = user
  return {
    userId,
    isSormland,
    paidSessionNumber,
    socialSecurity,
    freeCard,
    email,
    phoneNumber,
    gender,
    zipCode,
  }
}

export default connect(mapStateToProps)(PaymentSuccess)
